import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { germany } from '../initial/vars'

export default ({ pageContext: { page, lang } }) => {

  const { i18n, t } = useTranslation()
  const [regulamin, setWpData] = useState(null)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang, i18n]);
  // Set the language for SSR
  if (typeof window === 'undefined') {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    fetch('https://tycho.pl/kasa/wp-json/wp/v2/pages')
      .then(response => response.json())
      .then(data => setWpData({ data }.data.filter(data => data.slug === "regulamin")[0].content.rendered))
  }, []);

  var regexHtmlTags = /(<([^>]+)>)/ig

  const Wrapper = styled.div`
    max-width: 900px;
    padding: 48px 16px;
    margin: auto;
    text-align: ${props => props.textAlign};

     h1, h2 {
       text-align: center;
       line-height: 1.1;
     }

  `

  const Loading = `<h3 style="text-align: center; margin-top: 60px"> ${t('loading')}... </h3>`


  return (
    <Layout>
      <SEO title={t(`product::${page.node.title}`)} />
      <Wrapper textAlign={(page.node.slug === "koszty-dostawy" || page.node.slug === "imprint") ? 'center' : 'left'}  >
        {(page.node.slug === "regulamin" && !germany)
          ?
          <div dangerouslySetInnerHTML={{ __html: regulamin ? regulamin : Loading }} />
          :
          <ReactMarkdown allowDangerousHtml={true} children={t(`static::${page.node.content.replace(regexHtmlTags, "").trim()}`)} />
        }
      </Wrapper>
    </Layout>

  )
}
